<template>
  <b-modal id="modal-reiniciar-datos-proceso-electoral" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class=" fa fa-vote-yea fa-fw "></i>
          Reiniciar datos de proceso electoral
        </span>
      </div>
    </template>

    <div class="alert alert-custom alert-light-danger fade show" role="alert">
      <div class="alert-text">
        <div class="alert-icon text-center d-block mb-5"><i class="fa fa-exclamation-triangle"></i><span class="font-size-h1 font-weight-bold ml-3">¡ATENCIÓN!</span></div>
        Utilizando esta opción se reiniciarán todos los datos del proceso electoral '<b>{{ procesoElectoral.nombre }}</b
        >'. Esto incluye, pero no está limitado a, la eliminación de los datos de:
        <ul class="ml-8 mt-2">
          <li>Fichas del proceso electoral</li>
          <li>Respuestas obtenídas de cada turno</li>
          <li>Estadísticas y datos históricos</li>
          <li>Datos de autitoría asociados al proceso</li>
          <li>Incidentes: actos de violencia y otros</li>
          <li>Propaganda electoral</li>
          <li>Imágenes y archivos multimedia</li>
          <li>Sustento de gastos</li>
        </ul>
        Se crearán:
        <ul class="ml-8 mt-2">
          <li>Fichas para cada local de votación <u>no eliminado</u></li>
        </ul>
        No se verán afectados:
        <ul class="ml-8 mt-2">
          <li>Usuarios y credenciales de acceso</li>
          <li>Locales de votación</li>
          <li>Configuración de niveles de acceso a locales de votación</li>
        </ul>
        Si tie dudas de las implicancias de este proceso entonces <b>¡NO PROCEDA!</b>
      </div>
    </div>

    <b-form id="frm-reiniciar-datos-proceso-electoral">
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Confirmación:</label>
          <b-form-input id="input-3" v-model="reset.confirmacion" required placeholder="Escriba 'PROCEDER' para confirmar"></b-form-input>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-cog"></i> Iniciar proceso</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-proceso-electoral",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    procesoElectoralId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    procesoElectoral: {
      nombre: "",
    },
    reset: {
      confirmacion: null,
    },
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      if (!(await this.sweetConfirm("¿Desea continuar con este proceso?"))) {
        return;
      }

      if (!this.isFormValid("frm-reiniciar-datos-proceso-electoral")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      var model = {
        procesoElectoralId: this.procesoElectoralId,
        confirmacion: this.reset.confirmacion,
      };

      debugger;

      this.estaGuardando = true;
      await this.$http.get(this.apiRoute("ElectoralProcess", "ReiniciarDatosFichasProcesoElectoral"), { params: model }).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.sweetAlert(response.body.message, "success");
          this.mostrarModal = false;
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
    },
    fetchProcesoElectoral: function(procesoElectoralId) {
      if (procesoElectoralId) {
        this.$http
          .get(this.apiRoute("ElectoralProcess", "GetProcesoElectoralById"), {
            params: {
              ProcesoElectoralId: procesoElectoralId,
            },
          })
          .then(function(response) {
            this.procesoElectoral = response.body.data;
          });
      }
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.procesoElectoral.procesoElectoralId = this.procesoElectoralId;
    this.initParsley();
    this.fetchProcesoElectoral(this.procesoElectoral.procesoElectoralId);
  },
  destoyed: function() {},
};
</script>
