<template>
  <b-modal id="modal-add-edit-local-votacion" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" v-model="mostrarModal" dialog-class="modal-dialog-scrollable" v-if="mostrarModal" @close="handleModalClose">
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class=" fa fa-vote-yea fa-fw "></i>
          {{ procesoElectoral.procesoElectoralId ? "Editar" : "Agregar" }}
          proceso electoral
        </span>
      </div>
    </template>
    <!--Contenido de modal de | ID :
    {{ localVotacionId }}-->
    <b-form id="frm-add-edit-local-votacion">
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Nombre</label>
          <b-form-input id="input-1" v-model="procesoElectoral.nombre" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-1" v-model="procesoElectoral.fechaInicio" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-2" v-model="procesoElectoral.fechaFin" required />
        </div>
      </div>

      <!--Turno 01-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio (Turno 1)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-3" v-model="procesoElectoral.fechaInicioTurno1" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin (Turno 1)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-4" v-model="procesoElectoral.fechaFinTurno1" required />
        </div>
      </div>
      <!--Fin Turno 01-->

      <!--Turno 02-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio (Turno 2)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-5" v-model="procesoElectoral.fechaInicioTurno2" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin (Turno 2)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-6" v-model="procesoElectoral.fechaFinTurno2" required />
        </div>
      </div>
      <!--Fin Turno 02-->

      <!--Turno 03-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio (Turno 3)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-7" v-model="procesoElectoral.fechaInicioTurno3" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin (Turno 3)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-8" v-model="procesoElectoral.fechaFinTurno3" required />
        </div>
      </div>
      <!--Fin Turno 03-->

      <!--Turno 04-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio (Turno 4)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-9" v-model="procesoElectoral.fechaInicioTurno4" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin (Turno 4)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-10" v-model="procesoElectoral.fechaFinTurno4" required />
        </div>
      </div>
      <!--Fin Turno 04-->

      <!--Turno 05-->
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Inicio (Turno 5)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-11" v-model="procesoElectoral.fechaInicioTurno5" required />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha de Fin (Turno 5)</label>
          <br />
          <input type="datetime-local" class="form-control" id="datetime-12" v-model="procesoElectoral.fechaFinTurno5" required />
        </div>
      </div>
      <!--Fin Turno 05-->

      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Edición por Turnos (antes)</label>
          <b-form-input id="input-2" v-model="procesoElectoral.edicionTurnoMinutosAntes" required placeholder="Especificar minutos..."></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Edición por Turnos (despues)</label>
          <b-form-input id="input-3" v-model="procesoElectoral.edicionTurnoMinutosDespues" required placeholder="Especificar minutos..."></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Orden</label>
          <b-form-input id="input-4" v-model="procesoElectoral.orden" required placeholder=""></b-form-input>
        </div>
      </div>
      <div class="row pt-3" v-if="procesoElectoral.esActual == false">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox id="checkbox-2" size="lg" v-model="procesoElectoral.esEliminado"> </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button type="button" class="btn btn-secondary" @click.prevent="handleModalClose()"><i class="fa fa-ban"></i> Cancelar</button>
      <button type="button" class="btn btn-primary font-weight-bold" @click.prevent="doSave()"><i class="fa fa-save"></i> Guardar</button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-proceso-electoral",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    procesoElectoralId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    procesoElectoral: {
      procesoElectoralId: null,
      nombre: "",
      fechaInicio: null,
      fechaFin: null,
      fechaInicioTurno1: null,
      fechaInicioTurno2: null,
      fechaInicioTurno3: null,
      fechaInicioTurno4: null,
      fechaInicioTurno5: null,
      fechaFinTurno1: null,
      fechaFinTurno2: null,
      fechaFinTurno3: null,
      fechaFinTurno4: null,
      fechaFinTurno5: null,
      edicionTurnoMinutosAntes: null,
      edicionTurnoMinutosDespues: null,
      orden: null,
      esActual: false,
      esEliminado: false,
    },
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-local-votacion")) {
        return this.sweetAlert("Hay campos con valores incorrectos. Revise los datos ingresados.");
      }

      var model = {
        ...this.procesoElectoral,
      };

      debugger;

      this.estaGuardando = true;
      await this.$http.post(this.apiRoute("ElectoralProcess", "AddEditProcesoElectoral"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.sweetAlert(response.body.message, "success");
        }
      }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
      this.mostrarModal = false;
    },
    fetchProcesoElectoral: function(procesoElectoralId) {
      if (procesoElectoralId) {
        this.$http
          .get(this.apiRoute("ElectoralProcess", "GetProcesoElectoralById"), {
            params: {
              ProcesoElectoralId: procesoElectoralId,
            },
          })
          .then(function(response) {
            this.procesoElectoral = response.body.data;
          });
      }
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.procesoElectoral.procesoElectoralId = this.procesoElectoralId;
    this.initParsley();
    this.fetchProcesoElectoral(this.procesoElectoral.procesoElectoralId);
  },
  destoyed: function() {},
};
</script>
