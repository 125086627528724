<template>
  <div>
    <!--Modal AddEditProcesoElectoral-->
    <div v-if="edit.mostrarModal">
      <add-edit-proceso-electoral
        v-model="edit.mostrarModal"
        :proceso-electoral-id="edit.procesoElectoralId"
        @onSave="handleAddEditProcesoElectoralSaved"
      ></add-edit-proceso-electoral>
    </div>
    <div v-if="reset.mostrarModal">
      <reiniciar-datos-proceso-electoral
        v-model="reset.mostrarModal"
        :proceso-electoral-id="reset.procesoElectoralId"
        @onSave="handleReiniciarDatosFichasProcesoElectoral"
      ></reiniciar-datos-proceso-electoral>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class=" fa fa-vote-yea fa-fw "></i> Procesos Electorales
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-primary font-weight-bold mr-2"
                @click.prevent="doAddEditProcesoElectoral()"
                ><i class="la la-plus"></i> Nuevo</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-hover table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>
                      Fecha Inicio
                    </th>
                    <th>
                      Fecha Fin
                    </th>
                    <th>
                      Actual
                    </th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="proceso in procesos"
                    :key="proceso.procesoElectoralId"
                  >
                    <td>
                      {{ proceso.nombre }}
                    </td>
                    <td>
                      {{ proceso.fechaInicio | date }}
                    </td>
                    <td>
                      {{ proceso.fechaFin | date }}
                    </td>
                    <td>
                      <span
                        v-if="proceso.esActual == true"
                        class="label label-lg label-light-success label-inline"
                        >Si</span
                      >
                      <span
                        v-if="proceso.esActual == false"
                        class="label label-lg label-light-danger label-inline"
                        ><i
                          class="la la-refresh icon-md text-danger"
                          @click.prevent="
                            doEditEstadoActual(proceso.procesoElectoralId, '')
                          "
                        ></i>
                        No</span
                      >
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="
                            doAddEditProcesoElectoral(
                              proceso.procesoElectoralId
                            )
                          "
                        >
                          <span class="navi-icon"
                            ><i class="fa fa-pen text-dark"></i> Editar</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="
                            doReiniciarDatosFichasProcesoElectoral(
                              proceso.procesoElectoralId
                            )
                          "
                        >
                          <span class="navi-icon"
                            ><i class="fa fa-sync-alt text-dark"></i> Reiniciar
                            datos</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import addEditProcesoElectoral from "@/components/process/add-edit-proceso-electoral";
import reiniciarDatosProcesoElectoral from "@/components/process/reiniciar-datos-proceso-electoral";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "list-electoral-process",
  components: {
    addEditProcesoElectoral,
    reiniciarDatosProcesoElectoral,
  },
  props: {},
  data: () => ({
    procesos: [],
    edit: {
      mostrarModal: false,
      procesoElectoralId: null,
    },
    reset: {
      mostrarModal: false,
      procesoElectoralId: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doAddEditProcesoElectoral: function(procesoElectoralId) {
      this.edit.mostrarModal = true;
      this.edit.procesoElectoralId = procesoElectoralId;
    },

    handleAddEditProcesoElectoralSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchProcesosElectorales();
    },
    doReiniciarDatosFichasProcesoElectoral: function(procesoElectoralId) {
      this.reset.mostrarModal = true;
      this.reset.procesoElectoralId = procesoElectoralId;
    },
    handleReiniciarDatosFichasProcesoElectoral: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchProcesosElectorales();
    },
    fetchProcesosElectorales: function() {
      this.$http
        .post(this.apiRoute("ElectoralProcess", "ListProcesosElectorales"))
        .then(function(response) {
          this.procesos = response.body.data.items;
        });
    },
    doEditEstadoActual: function(procesoElectoralId, code) {
      var model = {
        ProcesoElectoralId: procesoElectoralId,
        Code: code,
      };

      this.$http
        .post(
          this.apiRoute("ElectoralProcess", "EditEstadoActualProcesoElectoral"),
          model
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            this.fetchProcesosElectorales();
          }
        }, this.mostrarMensajeExcepcionVueResource);
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Procesos Electorales" }]);
    this.fetchProcesosElectorales();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
